<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup>
	import { ref, onMounted } from 'vue';
	import DualBenefitItem from './components/DualBenefitItem.vue';
	import SkeletonBox from 'shared/components/skeleton/skeletonBox.vue';

	const props = defineProps({
		vm: {
			type: Object,
			default: () => {},
		},
	});

	const
		dualBenefitBlock = ref(null),
		observer = ref(null),
		videoId = ref(Math.floor(100000 + Math.random() * 900000)),
		videoProgress = ref({}),
		videoApiIsReady = ref(false);

	onMounted(() => {
		observer.value = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting === true && !videoProgress.value.blockIsVisible) {
				videoProgress.value.blockIsVisible = true;
			}
		}, { threshold: 0.05 });

		observer.value.observe(dualBenefitBlock.value);
	});

	function updateVideoProgress(updatedProgressObj) {
		videoProgress.value = { ...videoProgress.value, ...updatedProgressObj };
	}

	function updateApiProgress(value) {
		videoApiIsReady.value = value;
	}
</script>

<template>
	<div class="c-dual-benefit-block__container"
		 ref="dualBenefitBlock">
		<DualBenefitItem :dual-benefit-model="vm.CurrentBlock.LeftSideBenefit"
						 :video-id="`first-${videoId}`"
						 :video-progress="videoProgress"
						 :videoApiIsReady="videoApiIsReady"
						 @videoApiIsReady="updateApiProgress"
						 @changeVideo="updateVideoProgress">
			<slot name="leftcta"></slot>
			<template #skeleton>
				<SkeletonBox width="100%"
							 height="400" />
			</template>
		</DualBenefitItem>
		<DualBenefitItem :dual-benefit-model="vm.CurrentBlock.RightSideBenefit"
						 :video-id="`${videoId}`"
						 :video-progress="videoProgress"
						 :videoApiIsReady="videoApiIsReady"
						 @videoApiIsReady="updateApiProgress"
						 @changeVideo="updateVideoProgress">
			<slot name="rightcta"></slot>
			<template #skeleton>
				<SkeletonBox width="100%"
							 height="400" />
			</template>
		</DualBenefitItem>
	</div>
</template>
