<script setup>
	import { ref, computed, watch, nextTick } from 'vue';
	import { DsIconButton } from '@coloplast/design-system';
	import { track_content_engagement } from 'shared/services/tracking/contentTrackingService.ts';

	const props = defineProps({
		videoUrl: {
			type: String,
			required: false,
		},
		videoId: {
			type: String,
			required: false,
		},
		videoProgress: {
			type: Object,
			default: () => { },
		},
		videoApiIsReady: {
			type: Boolean,
			default: () => false,
		},
		headline: {
			type: String,
			default: '',
		},
	});

	let videoPlayer;

	const
		video = ref(null),
		videoIsPlaying = ref(false);

	const vimeoVideoParams = computed(() => {
		let params = '?autoplay=0&muted=1&controls=0&api=1&title=0&byline=0&portrait=0&sidedock=0&playsinline=1&autopip=none&pip=none&byline=0&responsive=1&loop=0';
		if (!window.marketingPermissions) {
			params += '&dnt=true';
		}
		return params;
	});

	const embedVideoId = computed(() => {
		const m = props.videoUrl.split('/');
		if (m) {
			return m[m.length - 1];
		} else {
			return null;
		}
	});

	const embedUrl = computed(() => {
		if (embedVideoId.value) {
			return `https://player.vimeo.com/video/${embedVideoId.value}${vimeoVideoParams.value}`;
		}
		else {
			return null;
		}
	});

	const emit = defineEmits(['changeVideo', 'videoApiIsReady', 'scriptLoading']);

	//when we scroll and block start visible on the page we start playing first video
	watch(() => props.videoProgress.blockIsVisible, (isVisible) => {
		if (videoPlayer && isVisible && !props.videoProgress.videoIsPlaying && props.videoId.includes('first')) {
			playVideo();
		}
	});

   //when one video is playing but user clicks on another one - just pause the current
	watch(() => props.videoProgress.videoId, (newVideoId) => {
		if (newVideoId != '' && videoIsPlaying.value && props.videoId != newVideoId) {
			pauseVideo();
		}
	});

	//looping the videos if no user action yet
	watch(() => props.videoProgress.videoEnded, (isEnded) => {
		if (videoPlayer && isEnded && props.videoProgress.videoId != props.videoId) {
			playVideo();
		}
	});

	//when player and vimeo api is ready just init player functionality
	watch(() => props.videoApiIsReady, (videoApiIsReady) => {
		if (videoApiIsReady) {
			initPlayer();
		}
	});

	//do not include Vimeo api twice for each video item
	if (!document.getElementById('videoapi-benefit')) {
		loadScript();
	}
	else {
		emit('scriptLoading', false);
	}

	function loadScript() {
		const videoApi = document.createElement('script');
		videoApi.src = 'https://player.vimeo.com/api/player.js';
		videoApi.async = true;
		videoApi.id = 'videoapi-benefit';

		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(videoApi, firstScriptTag);

		videoApi.onload = () => {
			emit('scriptLoading', false);
			nextTick(() => {
				emit('videoApiIsReady', true);
			});
		};
	}

	function initPlayer() {
		videoPlayer = new Vimeo.Player(video.value);
		videoPlayer.on('loaded', () => {
			if (props.videoProgress.blockIsVisible && !props.videoProgress.videoIsPlaying && props.videoId.includes('first')) {
				playVideo();
			}
		});
		videoPlayer.on('ended', () => {
			videoIsPlaying.value = !videoIsPlaying.value;
			videoPlayer.setCurrentTime(0);
			updateVideoProgress({
				videoIsPlaying: false,
				videoId: props.videoId,
				videoEnded: true,
			})
		});
	}

	const videoTracked = ref(false);

	function playVideo(userClick = false) {
		videoPlayer.play();
		updateVideoProgress({
			videoIsPlaying: true,
			videoId: props.videoId,
			videoEnded: false,
		})
		videoIsPlaying.value = !videoIsPlaying.value;

		if (userClick) {
			track_content_engagement('video', 'start', props.headline || 'no_data', props.videoUrl);
		} else if (!userClick && !videoTracked.value) {
			track_content_engagement('video', 'start', props.headline || 'no_data', props.videoUrl);
			videoTracked.value = true;
		}
	}

	function pauseVideo() {
		videoIsPlaying.value = !videoIsPlaying.value;
		videoPlayer.pause();
		if (props.videoProgress.videoId === props.videoId) {
			updateVideoProgress({
				videoIsPlaying: false,
				videoId: '',
				videoEnded: false,
			})
		}
	}

	function updateVideoProgress(obj) {
		emit('changeVideo', obj)
	}

</script>

<template>
	<div v-if="embedUrl"
		 class="c-video-item">
		<iframe
				ref="video"
				class="c-video-item__iframe"
				title="video"
				:data-src="embedUrl"
				allow="autoplay"
				autoplay
				:src="embedUrl">
		</iframe>

		<DsIconButton v-if="!videoIsPlaying"
					  aria-label="Icon button"
					  icon-name="play"
					  variant="secondary"
					  class="c-video-item__videocta"
					  :clickHandler="() => playVideo(true)" />
		<DsIconButton v-else
					  aria-label="Icon button"
					  icon-name="pause"
					  variant="secondary"
					  class="c-video-item__videocta"
					  :clickHandler="() => pauseVideo()" />
	</div>
</template>
