<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup>
	import { ref, computed } from 'vue';
	import DualBenefitVideo from './DualBenefitVideo.vue';
	import { DsText, DsIcon, dsUseIsMobile } from '@coloplast/design-system';

	const props = defineProps({
		dualBenefitModel: {
			type: Object,
			default: () => { },
		},
		videoId: {
			type: String,
			required: false,
		},
		videoProgress: {
			type: Object,
			default: () => { },
		},
		videoApiIsReady: {
			type: Boolean,
			default: () => false,
		},
	});

	const { isMobile } = dsUseIsMobile();

	const dynamicSize = computed(() => (isMobile?.value ? 'sm' : 'md'));

	const scriptLoading = ref(true);

	const emit = defineEmits(['changeVideo', 'videoApiIsReady']);

	function updateVideoProgress(updatedProgressObj) {
		emit('changeVideo', updatedProgressObj)
	}

	function updateLoading(value) {
		scriptLoading.value = value;
	}

	function updateApiProgress(value) {
		emit('videoApiIsReady', value);
	}
</script>

<template>
	<div class="c-dual-benefit-block__row">
		<slot
			  v-if="scriptLoading && dualBenefitModel.VideoUrl"
			  name="skeleton">
		</slot>
		<DualBenefitVideo
						  v-if="dualBenefitModel.VideoUrl"
						  :video-url="dualBenefitModel.VideoUrl"
						  :video-id="videoId"
						  :video-progress="videoProgress"
						  :videoApiIsReady="videoApiIsReady"
						  :headline="dualBenefitModel.Headline"
						  @scriptLoading="updateLoading"
						  @videoApiIsReady="updateApiProgress"
						  @changeVideo="updateVideoProgress">
		</DualBenefitVideo>
		<div class="c-dual-benefit-block__contentwrapper">
			<DsText v-if="dualBenefitModel.Headline"
					html-element="h3"
					text-type="heading-2xl"
					class="c-dual-benefit-block__contentheadline">
				<span v-html="dualBenefitModel.Headline"></span>
			</DsText>
			<div v-if="dualBenefitModel.Benefits && dualBenefitModel.Benefits.length"
				 class="c-dual-benefit-block__keybenefits">
				<div
					v-for="(keybenefit, index) in dualBenefitModel.Benefits"
					:key="index"
					class="c-dual-benefit-block__keybenefititem"
				 >
					<DsIcon v-if="keybenefit.Icon"
							:icon-name="keybenefit.Icon"
							:size="dynamicSize">
					</DsIcon>
					<DsText v-if="keybenefit.Text"
							html-element="span"
							text-style="regular"
							text-type="body-lg">
						<span v-html="keybenefit.Text"></span>
					</DsText>
				</div>
			</div>
			<DsText
				html-element="div"
				text-type="body-md"
				text-style="regular"
				class="c-dual-benefit-block__contentdescription"
			>
				<div v-html="dualBenefitModel.Description"></div>
			</DsText>
			<!--Cta Button-->
			<slot></slot>
		</div>
	</div>
</template>
