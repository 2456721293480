import { createApp } from 'vue';
import DualBenefitBlock from './DualBenefitBlock.vue';
import './c-dual-benefit-block.scss';

const dualBenefits = [...document.querySelectorAll('[data-dual-benefits]')];

if (dualBenefits) {
	dualBenefits.forEach((item) => {
		const app = createApp({
			name: 'DualBenefitBlockApp',
		});
		app.component('dual-benefit-block', DualBenefitBlock);
		app.mount(item);
	});
}